import React from 'react';

import { clientConfig } from '@/env/config';
import { ImageProps } from '../../../types';

import Styled from './index.styled';

const baseScale = 320;
const imageSizes = [320, 640, 960, 1280, 1600, 1920];
const path = clientConfig().mediaServer;

function buildSrcSet(src, imageType, format, bScale, length) {
	return imageSizes.map((size, index) => {
		const isHeight = length === 'height';
		const isJpg = format === 'jpg';
		const imageFormat = isJpg ? 'pjpeg' : format;
		const scale = isHeight ? bScale * (index + 2.5) : bScale * (index + 1);

		return `${path}${src}.${imageType}?imageformat=${imageFormat}&${length}=${scale} ${size}w`;
	});
}

function buildSrcSetForContentful(src, format, bScale, length) {
	return imageSizes.map((size, index) => {
		const isHeight = length === 'h';
		const isJpg = format === 'jpg' || format === 'webp';
		const imageFormat = isJpg ? 'jpg&fl=progressive' : format;
		const scale = isHeight ? bScale * (index + 2.5) : bScale * (index + 1);

		return `${src}?fm=${imageFormat}&${length}=${scale} ${size}w`;
	});
}
function buildSrcSetForFrontify(src, format, bScale, length) {
	return imageSizes.map((size, index) => {
		const isHeight = length === 'height';
		const isJpg = format === 'jpg';
		const imageFormat = isJpg ? 'jpeg' : format;
		const scale = isHeight ? bScale * (index + 2.5) : bScale * (index + 1);

		return `${src}?format=${imageFormat}&${length}=${scale} ${size}w`;
	});
}

export default function CustomImage({
	src,
	alt,
	imageType = 'jpg',
	objectFit = 'cover',
	width,
	height,
	length = 'width',
}: ImageProps) {
	const isContentful = src ? src.includes('images.ctfassets.net') : false;
	const isFrontify = src ? src.includes('frontify') : false;

	const srcSet = buildSrcSet(src, imageType, imageType, baseScale, length);
	const srcSetWebP = buildSrcSet(src, imageType, 'webp', baseScale, length);

	const srcSetForFrontify = buildSrcSetForFrontify(src, imageType, baseScale, length);
	const srcSetWebPForFrontify = buildSrcSetForFrontify(src, 'webp', baseScale, length);

	const sizes = `(min-width: 960px) 57.01vw, 90vw`;

	const srcSetDPI = `
		${path}${src}.${imageType}?imageformat=pjpeg&${length}=${baseScale * 2} 1x,
		${path}${src}.${imageType}?imageformat=pjpeg&${length}=${baseScale * 4} 2x`;

	const srcSetDPIFrontify = `
		${src}.${imageType}?format=jpeg&${length}=${baseScale * 2} 1x,
		${src}.${imageType}?format=jpeg&${length}=${baseScale * 4} 2x`;

	const lengthContentful = length.replace('height', 'h').replace('width', 'w');
	const srcSetContentful = buildSrcSetForContentful(src, imageType, baseScale, lengthContentful);
	const srcSetWebPContentful = buildSrcSetForContentful(src, 'webp', baseScale, lengthContentful);

	const srcSetDPIContentful = `
	 	${src}?fm=jpg&fl=progressive&${lengthContentful}=${baseScale * 2} 1x,
	 	${src}?fm=jpg&fl=progressive&${lengthContentful}=${baseScale * 4} 2x`;

	if (isContentful) {
		return (
			<Styled.Picture objectFit={objectFit}>
				<source type="image/webp" srcSet={srcSetWebPContentful.join(',')} sizes={sizes} />
				<source
					type={`image/${imageType}`}
					srcSet={srcSetContentful.join(',')}
					sizes={sizes}
				/>
				<img
					className="lazyload"
					alt={alt}
					data-src={`${src}?fm=jpg&fl=progressive&${lengthContentful}=${baseScale * 3}`}
					data-srcset={srcSetDPIContentful}
					width={width}
					height={height}
				/>

				<noscript>
					<source
						type="image/webp"
						srcSet={srcSetWebPContentful.join(',')}
						sizes={sizes}
					/>
					<source
						type={`image/${imageType}`}
						srcSet={srcSetContentful.join(',')}
						sizes={sizes}
					/>
					<img
						alt={alt}
						src={`${src}?fm=jpg&fl=progressive&${lengthContentful}=${baseScale * 3}`}
						srcSet={srcSetDPIContentful}
						width={width}
						height={height}
					/>
				</noscript>
			</Styled.Picture>
		);
	}

	if (isFrontify) {
		return (
			<Styled.Picture objectFit={objectFit}>
				<source type="image/webp" srcSet={srcSetWebPForFrontify.join(',')} sizes={sizes} />
				<source
					type={`image/${imageType}`}
					srcSet={srcSetForFrontify.join(',')}
					sizes={sizes}
				/>
				<img
					className="lazyload"
					alt={alt}
					data-src={`${src}?format=jpeg&${length}=${baseScale * 3}`}
					data-srcset={srcSetDPIFrontify}
					width={width}
					height={height}
				/>
				<noscript>
					<source
						type="image/webp"
						srcSet={srcSetWebPForFrontify.join(',')}
						sizes={sizes}
					/>
					<source
						type={`image/${imageType}`}
						srcSet={srcSetForFrontify.join(',')}
						sizes={sizes}
					/>
					<img
						alt={alt}
						src={`${src}${imageType}?format=jpeg&${length}=${baseScale * 3}`}
						srcSet={srcSetDPIFrontify}
						width={width}
						height={height}
					/>
				</noscript>
			</Styled.Picture>
		);
	}

	return (
		<Styled.Picture objectFit={objectFit}>
			<source type="image/webp" srcSet={srcSetWebP.join(',')} sizes={sizes} />
			<source type={`image/${imageType}`} srcSet={srcSet.join(',')} sizes={sizes} />
			<img
				className="lazyload"
				alt={alt}
				data-src={`${path}${src}.${imageType}?imageformat=pjpeg&${length}=${baseScale * 3}`}
				data-srcset={srcSetDPI}
				width={width}
				height={height}
			/>

			<noscript>
				<source type="image/webp" srcSet={srcSetWebP.join(',')} sizes={sizes} />
				<source type={`image/${imageType}`} srcSet={srcSet.join(',')} sizes={sizes} />
				<img
					alt={alt}
					src={`${path}${src}.${imageType}?imageformat=pjpeg&${length}=${baseScale * 3}`}
					srcSet={srcSetDPI}
					width={width}
					height={height}
				/>
			</noscript>
		</Styled.Picture>
	);
}
