import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, margins } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { smBodyRegular } from '../Typography/index.styled';

export const StyledLinkRulertel = styled.div`
	content: '';
	position: absolute;
	top: 100%;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${gap[8]} ${gap[16]};
	background-color: var(--secondaryBackground);
	z-index: 1;

	& > svg {
		height: 24px;
		width: 24px;
		margin-right: ${gap[8]};
	}

	${above(breakpoints[600], () => css`
		padding-right: ${gap[24]};
		padding-left: ${gap[24]};
	`)}

	${above(breakpoints[960], () => css`
		right: ${margins[48]};
	`)}

	${above(breakpoints[1440], () => css`
		right: ${margins[80]};
	`)}

	${above(breakpoints[1920], () => css`
		right: ${margins[120]};
	`)}
`;

export const StyledLinkRulertel__link = styled(smBodyRegular)`
	color: var(--black);
	text-decoration: none;
	cursor: pointer;
`;
