import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { above } from '../../../styles/tools/media';

type LogoLinkProps = {
	footer?: boolean;
	doubleLines?: boolean;
};

export const LogoLink = styled.a<LogoLinkProps>`
	display: inline-block;
	width: 130px;
	cursor: pointer;

	${props =>
		props.footer &&
		css`
			${above(
				breakpoints[960],
				() => css`
					width: 210px;
					height: 32px;
				`
			)}
		`}

	${props =>
		!props.footer &&
		css`
			${above(
				breakpoints[600],
				() => css`
					width: 156px;
				`
			)}

			${above(
				breakpoints[1440],
				() => css`
					width: 180px;
				`
			)}
		`}
`;
