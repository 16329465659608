import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

// import { Subsite } from '../../../types';

import PhoneSVG from '../../../svgs/UI/Phone';
// import ExternalLink from '../../../svgs/UI/ExternalLink';
import { StyledLinkRulertel, StyledLinkRulertel__link } from './index.styled';

// const WorksLink = ({ iconColor }) => (
// 	<>
// 		<ExternalLink fill={iconColor} />
// 		<StyledLinkRulertel__link href="" as="a" href="">
// 			Become an Ambassador
// 		</StyledLinkRulertel__link>
// 	</>
// );

// const GeneralLink = iconColor => (
// 	<>
// 		<PhoneSVG fill={iconColor} />

// 		<StyledLinkRulertel__link
// 			as="a"
// 			href="tel:08007310300"
// 			className="rulertel"
// 		>
// 			08007310300
// 		</StyledLinkRulertel__link>
// 	</>
// );

const Link = (
	// { toggleName },
	props
) => {
	const theme = useContext(ThemeContext);

	return (
		<StyledLinkRulertel {...props}>
			{/* TODO:
			1: change the icon color to a proper 'Works' theme, when that exists
			2: uncomment the code bellow to when Become an Ambassador is ready to release
			*/}

			{/* {toggleName === TSite.BruntwoodWorks && <WorksLink iconColor="#FB6600" />} */}

			{/* {toggleName !== TSite.BruntwoodWorks && (
				<GeneralLink iconColor={theme.primaryAccent} />
			)} */}

			<PhoneSVG fill={theme.primaryAccent} />

			<StyledLinkRulertel__link as="a" href="tel:08007310300" className="rulertel">
				0800 731 0300
			</StyledLinkRulertel__link>
		</StyledLinkRulertel>
	);
};

export default Link;
