import React from 'react';
import { Document } from '@contentful/rich-text-types';

export enum cookieNames {
	allowEssentialCookies = 'allowEssentialCookies',
	allowPerformanceCookies = 'allowPerformanceCookies',
}

export enum Subsite {
	BruntwoodGroup = 'Bruntwood Group',
	BruntwoodSciTech = 'Bruntwood SciTech',
	BruntwoodWorks = 'Bruntwood Works',
}

export type HiddenInputProps = {
	id: string;
	name: string;
	value?: string;
};

export type TextBlockTypes = {
	title: string;
	copyList: string[];
};

export type SuccessTypes = {
	title: string;
	copy?: string;
};

export type FieldTypes = {
	id: string;
	type: string;
	label: string;
	name: string;
	action: string;
	required?: boolean;
	message?: string;
	pattern?: string;
	halfWidth?: boolean;
};

export type ButtonTypes = {
	text: string;
	category: string;
	action: string;
	label: string;
	buttonText: string;
	buttonCategory: string;
	buttonLabel: string;
	buttonAction: string;
};

export type FormTypes = {
	endpoint?: string;
	confirmationPageRedirect?: string;
	name: string;
	subject: string;
	title: string;
	fields: FieldTypes[];
	button: ButtonTypes;
	emailType?: string;
	emailAddressesToSend?: string[];
};

export type ContactTypes = {
	dataGALabel?: string;
	textBlock?: TextBlockTypes;
	hidePhoneNumber: boolean;
	hideForm?: boolean;
	form: FormTypes;
	contactSuccess: SuccessTypes;
	gdprCopy?: string;
	gdprRichText?: Document;
	compRef: any;
	pardot: boolean;
	isTabbedComponent?: boolean;
	anchorId?: string;
};

export type FooterTypes = {
	address: string;
	links: {
		url: string;
		title: string;
		dataGAAction?: string;
		dataGACategory?: string;
	}[];
	subsite: string;
};

export type ButtonProps = {
	as?: any;
	buttonText?: string;
	buttonLink?: string;
	buttonCategory?: string;
	buttonAction?: string;
	buttonLabel?: string;
	buttonType?: string;
	callback?: (event: MouseEvent) => void;
	iconName?: string;
	iconSize?: string;
	loading?: boolean;
	loadingText?: string;
	href?: string;
	target?: string;
	rel?: string;
	type?: string;
	iconPosition?: 'left' | 'right';
	isAnchorLink?: boolean;
};

export type ImageProps = {
	id?: string;
	src: string;
	alt: string;
	caption?: string;
	imageType?: string;
	objectFit?: string;
	width: string;
	height: string;
	length?: string;
};

export type ArticleCardProps = {
	link?: string;
	title: string;
	subtitle: string;
	description: string | React.ReactNode;
	image: Pick<ImageProps, 'src' | 'alt'>;
	isExternalLink?: boolean;
	theme?: any;
	styling?: string | null;
	subSite?: string;
};
