import React from 'react';
import dynamic from 'next/dynamic';

import { Subsite } from '../../../types';

import BruntwoodLogoSVG from '../../../svgs/BruntwoodLogo';
import BruntwoodSciTechLogoSVG from '../../../svgs/BruntwoodSciTechLogo';
import BruntwoodWorksLogoSVG from '../../../svgs/BruntwoodWorksLogo';
import CrossSVG from '../../../svgs/UI/Cross';
import HamburgerSVG from '../../../svgs/UI/Hamburger';
import { useSubSite } from '../../providers/SubSite';
import LinkNav from '../../atoms/LinkNav';
import LinkRulertel from '../../atoms/LinkRulertel';
import LogoLink from '../../atoms/LogoLink';
import {
	StyledHeader,
	StyledHeader__Toggle,
	Header__list,
	Header__list__item,
	Header__mobileToggle,
	Header__wrap,
} from './index.styled';

// Dynamic imports
const MegaMenu = dynamic(() => import('../MegaMenu'));
const MobileMenu = dynamic(() => import('../MobileMenu'));

const Headers = ({
	setOpenMobileMenu,
	openMobileMenu,
	selectedNavToggle,
	setSelectedNavToggle,
	headers,
}) => {
	const currentSubSite = useSubSite();

	const subnavs = [
		Subsite.BruntwoodGroup,
		Subsite.BruntwoodSciTech,
		Subsite.BruntwoodWorks,
	];

	const handleSetSelectedNavToogle = key => {
		// eslint-disable-next-line no-unused-expressions
		typeof window.orientation === 'undefined' && setSelectedNavToggle(key);
	};

	return (
		<StyledHeader__Toggle>
			<Header__wrap>
				<Header__list topLevelNav>
					{headers &&
						headers.map((header, index) => (
							<Header__list__item
								key={header.headingLink.id}
								topLevelNav
								selected={selectedNavToggle === subnavs[index]}
								toggleName={subnavs[index]}
								onMouseEnter={() => handleSetSelectedNavToogle(subnavs[index])}
								onFocus={() => handleSetSelectedNavToogle(subnavs[index])}
								onMouseLeave={() => handleSetSelectedNavToogle(currentSubSite)}
							>
								<LinkNav
									{...header.headingLink}
									topLevelNav
									toggleName={subnavs[index]}
									selectedNavToggle={selectedNavToggle}
								/>
								<StyledHeader
									toggleName={subnavs[index]}
									selectedNavToggleName={selectedNavToggle}
								>
									<Header__wrap>
										{subnavs[index] === Subsite.BruntwoodGroup && (
											<LogoLink {...header.headingLink}>
												<BruntwoodLogoSVG />
											</LogoLink>
										)}
										{subnavs[index] === Subsite.BruntwoodSciTech && (
											<LogoLink {...header.headingLink} doubleLines>
												<BruntwoodSciTechLogoSVG />
											</LogoLink>
										)}
										{subnavs[index] === Subsite.BruntwoodWorks && (
											<LogoLink {...header.headingLink} doubleLines>
												<BruntwoodWorksLogoSVG />
											</LogoLink>
										)}
										<Header__list>
											{header.links.map(link => (
												<Header__list__item key={link.headingLink.id}>
													<LinkNav
														{...link.headingLink}
														toggleName={header.headingLink.title}
													/>

													{link.linkColumns.length > 0 && (
														<MegaMenu
															columns={link.linkColumns}
															illustration={link.illustration}
														/>
													)}
												</Header__list__item>
											))}
										</Header__list>

										<Header__mobileToggle
											aria-label="Show menu"
											aria-haspopup="true"
											onClick={() => setOpenMobileMenu(!openMobileMenu)}
										>
											{openMobileMenu === false && <HamburgerSVG />}
											{openMobileMenu && <CrossSVG />}
										</Header__mobileToggle>
										<LinkRulertel toggleName={header.toggledName} />

										<MobileMenu
											selectedNavToggle={selectedNavToggle}
											hidden={!openMobileMenu}
											doubleNav
											toggleName={header.headingLink.title}
											data={header.links}
										/>
									</Header__wrap>
								</StyledHeader>
							</Header__list__item>
						))}
				</Header__list>
			</Header__wrap>
		</StyledHeader__Toggle>
	);
};

export default Headers;
