function aspectRatio(ratio: string = '1:1') {
  const ratioArr = ratio.split(':');
	const ratioWidth = parseInt(ratioArr[0], 10);
	const ratioHeight = parseInt(ratioArr[1], 10);
	const percentage = (ratioHeight / ratioWidth) * 100;
	const result = `${Math.round((percentage + Number.EPSILON) * 100) / 100}%`;

	return result;
}

export default aspectRatio;
