import { useState, useEffect } from 'react';

const isClient = typeof window === 'object';

const getWidth = () => isClient && window.innerWidth;

function useCurrentWidth() {
	// save current window width in the state object
	const [width, setWidth] = useState(getWidth());

	// in this case useEffect will execute only once because
	// it does not have any dependencies.
	useEffect(() => {
		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout(timeoutId);
			// change width from the state object after 200 milliseconds
			timeoutId = setTimeout(() => setWidth(getWidth()), 200);
		};
		// set resize listener
		window.addEventListener('resize', resizeListener);

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	return width;
}

export default {
	useCurrentWidth,
};
