import styled, { css } from 'styled-components';

import { Subsite } from '../../../types';

import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import {
	xsBodyRegular,
	smBodyRegular,
} from '../Typography/index.styled';

const PropNames = {
	selected: 'selected',
	dataToggleName: 'data-toggle-name',
};

type LinkNavProps = {
	selected?: string
	'data-toggle-name'?: string
};

export const StyledLinkNav__toggle = styled(xsBodyRegular)<LinkNavProps>`
	display: block;
	width: 100%;
	max-width: 120px;
	height: 100%;
	padding-right: ${gap[16]};
	padding-left: ${gap[16]};
	text-align: center;
	color: ${props => {
		if (props[PropNames.selected]) {
			if (props[PropNames.dataToggleName] === Subsite.BruntwoodWorks) {
				return 'var(--black)';
			}
			return 'var(--white)';
		}
			return 'var(--black)';
	}};
	text-decoration: none;

	${above(
		breakpoints[600],
		() => css`
			max-width: unset;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-right: ${gap[20]};
			padding-left: ${gap[20]};
		`
	)}
`;

export const StyledLinkNav__expandable = styled(smBodyRegular)<LinkNavProps>`
	display: block;
	width: 100%;
	height: 100%;
	padding-right: ${gap[16]};
	padding-left: ${gap[16]};
	color: ${props => {
		if (props[PropNames.dataToggleName] === Subsite.BruntwoodGroup || props[PropNames.dataToggleName] === Subsite.BruntwoodSciTech) {
			return 'var(--white)';
		}
			return 'var(--black)';
	}};
	text-decoration: none;
	/* transition: background-color, color 125ms ease-in; */

	&[aria-expanded='true'] {
		color: var(--black);
		background-color: var(--white);
	}

	${above(
		breakpoints[960],
		() => css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-right: ${gap[20]};
			padding-left: ${gap[20]};
		`
	)}
`;
