import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';

export const StyledMegaMenu = styled.div`
	display:none;
	position: absolute;
	top: 56px;
	right: 0;
	left: 0;
	width: 100%;
	height: auto;
	background-color: transparent;
	z-index: 100;

	${below(
		breakpoints[600],
		() => css`
			display: none;
			visibility: hidden;
		`
	)}

	${above(
		breakpoints[600],
		() => css`
			top: 62px;
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			top: 72px;
		`
	)}
`;

export const StyledAfterGradient = styled.div`
	position: relative;
	content: '';
	bottom: 0;
	height: 100vh;
	width: 100%;
	display: block;
	background-color: rgba(0, 0, 0, 0.7);
`;

export const MegaMenu__container = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	background-color: var(--white);
	padding-left: ${margins[48]};
	padding-right: ${margins[48]};
	position: relative;

	${above(
		breakpoints[600],
		() => css`
			padding-right: ${margins[24]};
			padding-left: ${margins[24]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			padding-right: ${margins[48]};
			padding-left: ${margins[48]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			padding-right: ${margins[80]};
			padding-left: ${margins[80]};
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			padding-left: ${spacing[120]};
			padding-right: ${spacing[120]};
		`
	)}

	&:after {
		display: none;

		${above(
			breakpoints[960],
			() => css`
				content: '';
				display: block;
				position: absolute;
				right: 0;
				bottom: -24px;
				left: 0;
				height: 24px;
				margin-left: ${spacing[48]};
				background-color: var(--primaryAccent);
				z-index: 100;
			`
		)}

		${above(
			breakpoints[1440],
			() => css`
				bottom: -32px;
				height: 32px;
				margin-left: ${spacing[64]};
			`
		)}
	}
`;

export const MegaMenu__linkRow = styled.ul`
	display: flex;
	flex-flow: row nowrap;
	padding: ${gap[0]};
	flex: 1;
	text-align: left;
`;

export const MegaMenu__linkColumn = styled.li`
	display: flex;
	flex-flow: column nowrap;
	flex-basis: ${props => (props.isWideColumn === true ? '500px' : '160px')};
	padding-top: ${gap[56]};
	padding-right: ${gap[0]};
	padding-bottom: ${gap[24]};
	padding-left: ${gap[24]};

	&:first-of-type {
		padding-left: ${gap[0]};
		margin-left: 72px;
	}

	${above(
		breakpoints[960],
		() => css`
			padding-top: ${gap[48]};
			padding-bottom: ${gap[48]};
			flex-grow: 0;

			&:first-of-type {
				padding-left: ${gap[0]};
				margin-left: ${gap[48]};
			}
		`
	)}
`;
