import React from 'react';
import Browser from '../../../lib/browser';
import { StyledLinkNav__toggle, StyledLinkNav__expandable } from './index.styled';

const Link = ({
	url,
	dataGACategory,
	dataGAAction,
	dataGALabel,
	target,
	title,
	topLevelNav,
	toggleName,
	selectedNavToggle,
}) => (
	<>
		{topLevelNav && (
			<StyledLinkNav__toggle
				href={url}
				as="a"
				data-ga-action={dataGAAction}
				data-ga-category={dataGACategory}
				data-ga-label={dataGALabel}
				selected={selectedNavToggle === toggleName}
				target={target}
				onClick={event => Browser.handleClick(event, url)}
				rel="noopener noreferrer"
				data-toggle-name={toggleName}
			>
				{title}
			</StyledLinkNav__toggle>
		)}

		{!topLevelNav && (
			<StyledLinkNav__expandable
				href={url}
				as="a"
				data-ga-action={dataGAAction}
				data-ga-category={dataGACategory}
				data-ga-label={dataGALabel}
				target={target}
				onClick={event => Browser.handleClick(event, url)}
				data-toggle-name={toggleName}
				rel="noopener noreferrer"
				data-top-level-nav
			>
				{title}
			</StyledLinkNav__expandable>
		)}
	</>
);

export default Link;
