import React from 'react'

const Hamburger = ({ fill = 'white' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
			fill={fill}
		/>
	</svg>
)

export default Hamburger
