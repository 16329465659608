import Head from 'next/head';
import 'lazysizes';
import { clientConfig } from '@/env/config';
import { gtmNoScript, gtmScript } from '../../../lib/tracking';

const config = clientConfig();
export default function PageContainer({
	title,
	description,
	noindex,
	canonical,
	children,
	openGraphImageUrl,
}) {
	const mediaServer = config.mediaServer.split('/images');

	return (
		<>
			<Head>
				<title>{title || 'Bruntwood: Offices, Meeting rooms & Serviced offices'}</title>

				<meta name="viewport" content="width=device-width, initial-scale=1.0" />

				{description !== false && (
					<meta
						name="description"
						content={
							description ||
							'Bruntwood has over 100 properties across the UK, providing affordable Office space, Serviced offices, Retail & Lab space on flexible contracts.'
						}
					/>
				)}

				{noindex === true && <meta name="robots" content="noindex" />}

				<link rel="canonical" href={canonical} />

				<link rel="preconnect" href="https://www.googletagmanager.com" />
				<link rel="preconnect" href={mediaServer[0]} />

				<link rel="dns-prefetch" href="https://www.google-analytics.com" />
				<link rel="dns-prefetch" href="https://connect.facebook.net" />
				<link rel="dns-prefetch" href="https://script.hotjar.com" />
				<link rel="dns-prefetch" href="https://static.ads-twitter.com" />
				<link rel="dns-prefetch" href="https://analytics.nyltx.com" />
				<link rel="dns-prefetch" href="https://cdn.pardot.com" />
				<link rel="dns-prefetch" href="https://analytics.twitter.com" />
				<link rel="dns-prefetch" href="https://static.olark.com" />

				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={canonical} />
				<meta property="og:site_name" content="Bruntwood" />
				<meta property="og:image" content={openGraphImageUrl} />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@Bruntwood_UK" />
				{/* // eslint-disable-next-line react/no-danger */}
				<script dangerouslySetInnerHTML={{ __html: gtmScript }} defer />
			</Head>
			{/* // eslint-disable-next-line react/no-danger */}
			<div dangerouslySetInnerHTML={{ __html: gtmNoScript }} />
			{children}
		</>
	);
}
