import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useSubSite } from '../../providers/SubSite';
import hooks from '../../../lib/hooks';
import PageContainer from '../../molecules/PageContainer';
import Headers from '../Headers';
import { resolveOpenGraphImage } from '../../../lib/openGraph';

// Dynamic imports
const Footer = dynamic(() => import('../../atoms/Footer'));

export default function Page({
	title,
	description,
	noindex,
	canonical,
	children,
	headers,
	components,
	footers
}) {
	const currentSubSite = useSubSite();
	const [selectedNavToggle, setSelectedNavToggle] = useState(currentSubSite);
	const [openMobileMenu, setOpenMobileMenu] = useState(false);

	const currentWidth = hooks.useCurrentWidth();

	if (currentWidth >= 1140) {
		// eslint-disable-next-line no-unused-expressions
		openMobileMenu && setOpenMobileMenu(false);
	}
	useEffect(() => {
		if (openMobileMenu) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflowY = 'scroll';
			document.body.style.overflowX = 'hidden';
		}
	});

	const openGraphImageUrl = resolveOpenGraphImage(components);

	function headersPropsAreEqual(prevHeaders, nextHeaders) {
		return (
			prevHeaders.selectedNavToggle === nextHeaders.selectedNavToggle &&
			prevHeaders.headers === nextHeaders.headers
		);
	}

	const MemoizedHeaders = React.memo(Headers, headersPropsAreEqual);

	return (
		<PageContainer
			title={title}
			description={description}
			noindex={noindex}
			canonical={canonical}
			openGraphImageUrl={openGraphImageUrl}
		>
			<MemoizedHeaders
				openMobileMenu={openMobileMenu}
				setOpenMobileMenu={setOpenMobileMenu}
				selectedNavToggle={selectedNavToggle}
				setSelectedNavToggle={setSelectedNavToggle}
				headers={headers}
			/>

			<main>{children}</main>
			{footers && <Footer footers={footers} />}
		</PageContainer>
	);
}
