/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
function findFirstImageUrl(data, depth) {
	depth = depth || 1;

	let imageUrl = null;

	// Iterate through all of the properties of this object, to see if it is an image.
	for (const propName in data) {
		const obj = data[propName];
		if (
			obj != null &&
			obj.imageType != null &&
			obj.imageType.length > 0 &&
			obj.src != null &&
			obj.src.length > 0
		) {
			imageUrl = obj.src;
			break;
		}
	}

	// If we didn't find an image, recursively check all of this object's object properties.
	// The depth check is to prevent infinite recursion.
	if (imageUrl == null && depth < 20) {
		for (const propName in data) {
			const obj = data[propName];
			if (obj != null && typeof obj === 'object') {
				imageUrl = findFirstImageUrl(obj, depth + 1);
				if (imageUrl != null) {
					break;
				}
			}
		}
	}

	return imageUrl;
}

export function resolveOpenGraphImage(components) {
	const firstImageUrl =
		findFirstImageUrl(components) ||
		'https://bruntwood.co.uk/media/4291/coworking-hero-image-optimised.jpg';

	return firstImageUrl;
}
