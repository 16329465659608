import styled from 'styled-components';

type PictureProps = {
	objectFit: string
};

const Picture = styled.picture <PictureProps>`
	position: relative;
	display: block;
	background-color: var(--primaryAccent);

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: ${props => props.objectFit};
	}
`;

export default {
	Picture
};
