import React, { useContext } from 'react';

import { Subsite } from '../../types';

export const getSubSite = (tags): Subsite => {
	const slug =
		typeof window === 'object' && window.location.pathname.split('/')[1];
	const tagsInc = tags?.length ? tags : [];
	if (tagsInc.includes('SciTech') || slug === 'scitech') {
		return Subsite.BruntwoodSciTech;
	}
	if (tagsInc.includes('Works') || slug === 'works') {
		return Subsite.BruntwoodWorks;
	}
	return Subsite.BruntwoodGroup;
};

export const SubSiteContext = React.createContext(Subsite.BruntwoodGroup);

export const useSubSite = () => useContext(SubSiteContext);
