import React from 'react';
import Browser from '../../../lib/browser';
import { LogoLink } from './index.styled';

const Link = ({ url, dataGACategory, dataGAAction, dataGALabel, children, footer }) => (
	<LogoLink
		href={url}
		data-ga-action={dataGAAction}
		data-ga-category={dataGACategory}
		data-ga-label={dataGALabel}
		onClick={event => Browser.handleClick(event, url)}
		footer={footer}
		aria-label="Bruntwood"
	>
		{children}
	</LogoLink>
);

export default Link;
